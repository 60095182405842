<template>
  <div class="page">
    <div class="c-emailsent c-emailsent_h-800">
      <div class="c-emailsent__body c-emailsent__body_top-208">
        <div id="apiLoad">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div id="apiPage">
          <img src = "@/assets/images/email-check.svg" alt="email-check">
          <h2 class="c-emailsent__title">New API Key <span class="c-emailsent__title_black">Created</span></h2>
          <p class="c-emailsent__content">
            For security purposes, your API Secret Key will only be displayed<br /> once at the point of confirmation.
            If you lose this key, you will need<br /> to delete your API and set up a new one.
          </p>


          <div class="c-emailsent__api-container">
            <div class="c-emailsent__api-data-list">
              <h1 class="c-emailsent__api-title">API Key</h1>
              <p class="c-emailsent__api-info">
                <span class="c-emailsent__api-info-key">{{apiKey}}</span>
              </p>
            </div>
            <div class="c-emailsent__api-data-list">
              <h1 class="c-emailsent__api-title">Secret Key</h1>
              <div id="secretKey" class="c-emailsent__api-info c-emailsent__api-info_cursor-copy" @click="copySecretKey">
                <span id="apiSecretKey" class="c-emailsent__api-info-key">{{apiSecretKey}}</span>
                <span id="copyApiSecretKey" class="c-emailsent__api-info-btn">
                  <img src = "@/assets/images/copy.svg" alt="copyImg">
                </span>
              </div>
            </div>
          </div>


          <button @click="getRedirectApi" class="c-emailsent__btn-add-confirm">Go to API Management</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'NewAPIKeyRequest',
  components: {
  },
  data() {
    return {
      apiKey:'',
      apiSecretKey:'',
    }
  },
  mounted:function(){
    let url = new URL(window.location.href);
    localStorage.setItem("apiKeyURL", url);
    $('#apiPage').hide()
    if (localStorage.getItem('vuex') == null) {
      this.$router.push('/signin').catch(() => {})
    }
    let searchParams = new URLSearchParams(url.search);
    let key = searchParams.get('api-key')
    let code = searchParams.get('code')
    let typeConfirm = (searchParams.get('action')=="confirm"?1:0)

    this.$http.post(this.createApiUrlChestNut('api-keys/' + key +(typeConfirm?"/confirm?":"/reject?")),{
      "confirmationCode": code
    }).then((response) =>
    {
      if (!typeConfirm) {
        $('#apiPage').hide()
        this.$router.push('/emailsent-apikeyrejected').catch(() => {})
      } else if (typeConfirm) {
        $('#apiLoad').hide()
        $('#apiPage').show()
      }
      this.apiKey = response.body.apiKey
      this.apiSecretKey = response.body.secret
      localStorage.setItem('api-key',response);
      localStorage.removeItem("apiKeyURL");
    },err => {
      console.log(err)
      localStorage.removeItem("apiKeyURL");
      this.$router.push('/emailsent-apikeyexpired').catch(() => {})
    })
  },
  methods: {
    copySecretKey:function(){
      let max_length = 30
      let deposit_address = document.getElementById("apiSecretKey").innerHTML
      if (deposit_address.length > max_length)
          deposit_address = deposit_address.substring(0, max_length) + "..."

      this.copyToClipboard(document.getElementById("apiSecretKey"));
      this.showNotifSuccess(deposit_address + ' ' + 'has been copied to your clipboard.','success')
    },
    getRedirectApi() {
      window.location = 'dashboard/#/checker'
    },
    copyToClipboard(elem) {
      // create hidden text element, if it doesn't already exist
      var targetId = "_hiddenCopyText_";
      var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
      var origSelectionStart, origSelectionEnd;
      if (isInput) {
          // can just use the original source element for the selection and copy
          target = elem;
          origSelectionStart = elem.selectionStart;
          origSelectionEnd = elem.selectionEnd;
      } else {
          // must use a temporary form element for the selection and copy
          target = document.getElementById(targetId);
          if (!target) {
              var target = document.createElement("textarea");
              target.style.position = "absolute";
              target.style.left = "-9999px";
              // target.style.top = "0";
              target.id = targetId;
              document.body.appendChild(target);
          }
          target.textContent = elem.textContent;
      }
      // select the content
      var currentFocus = document.activeElement;
      target.focus();
      target.setSelectionRange(0, target.value.length);

      // copy the selection
      var succeed;
      try {
        succeed = document.execCommand("copy");
      } catch(e) {
        succeed = false;
      }
      // restore original focus
      if (currentFocus && typeof currentFocus.focus === "function") {
          currentFocus.focus();
      }

      if (isInput) {
          // restore prior selection
          elem.setSelectionRange(origSelectionStart, origSelectionEnd);
      } else {
          // clear temporary content
          target.textContent = "";
      }
      return succeed;
    }
  }
}
</script>
